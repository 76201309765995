/* src/components/PostCard/PostCard.css */

.post-card {
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: var(--color4);
  overflow: hidden; /* Ensure content doesn't spill out */
}

.post-image {
  width: 100%;
  height: auto;
  display: block;      /* Remove extra space below images */
  border-radius: 10px 10px 0 0;
  object-fit: cover;   /* Ensure image covers the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
}
.image-container {
  position: relative; /* Establishes a positioning context */
  width: 100%;
}
/* Style for the version badge */
.version-badge {
  position: absolute;
  top: 8%;
  left: -45%;
  width: 50%;            /* Badge width relative to image container */
  aspect-ratio: 50 / 1;   /* Ensure the badge is square */
  background-color: var(--color4);
  color: var(--color5);
  transform: translate(50%, -50%) rotate(-45deg);
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: clamp(0.4rem, 1vw, 0.9rem); /* Responsive text size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
/* Style for the updated badge */

.updated-badge {
  position: absolute;
  top: 10%;
  left: -43%;
  width: 50%;            /* Badge width relative to image container */
  aspect-ratio: 50 / 1;  /* Ensure the badge has the same aspect ratio */
  background-color: var(--color1); /* Different color to distinguish */
  color: var(--color4);
  transform: translate(50%, -50%) rotate(-45deg);
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: clamp(0.4rem, 1vw, 0.9rem); /* Responsive text size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: fadeIn 0.5s ease-in-out;
}
/* Style for the updated badge */
.new-badge {
  position: absolute;
  top: 10%;
  left: -43%;
  width: 50%;            /* Badge width relative to image container */
  aspect-ratio: 50 / 1;  /* Ensure the badge has the same aspect ratio */
  background-color: var(--color1); /* Different color to distinguish */
  color: var(--color4);
  transform: translate(50%, -50%) rotate(-45deg);
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: clamp(0.4rem, 1vw, 0.9rem); /* Responsive text size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: fadeIn 0.5s ease-in-out;
}
/* Scrolling text animation */
.new-scrolling-text {
  display: inline-block;
  animation: scrollText 5s linear infinite;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes scrollText {
  0% {
    transform: translateX(-400%);
  }
  100% {
    transform: translateX(300%);
  }
}


.no-image {
  width: 100%;
  height: 150px; /* Increased height for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background-color: #f0f0f0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.no-image span {
  color: #999;
  font-size: 16px; /* Increased font size */
}

.post-details {
  padding: 10px; /* Increased padding for better spacing */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap between elements */
  
}

/* New container for actions and download */
.actions-download-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; /* Add gap for better spacing */
}

/* Left side: buttons */
.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  
}

/* Existing styles for action buttons */
.action-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 5px; /* Increased padding */
  border-radius: 10px;
  background-color: var(--color3);
  color: var(--color5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  border: 1px solid var(--color1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  background-color: var(--color3-hover);
  transform: translateY(-2px);
}

.logo-icon {
  width: 25px; /* Adjusted size for better alignment */
  height: 25px;
  filter: grayscale(20%);
}

/* Or text styling */
.or-text {
  font-weight: bold;
  align-self: center;
  color: var(--color5);
}

/* Right side: download and ownership */
.download-container {
  display: flex;
  align-items: center;
  padding: 5px 5px;
}

.ownership-container {
  display: flex;
  color: var(--color5);
  align-items: center;
  gap: 5px;
}

.status-icon {
  width: 20px;
  height: 20px;
}
html.dark-theme .status-icon {
  width: 30px;
  height: 30px;
  filter: brightness(0) invert(1); /* This will change the image to white */
}
.status-icon-locked {
  width: 30px;
  height: 30px;
}
html.dark-theme .status-icon-locked {
  width: 30px;
  height: 30px;
  filter: brightness(0) invert(1); /* This will change the image to white */
}
.download-button {
  background-color: var(--color3);
  color: var(--color5);
  padding: 6px 16px; /* Increased padding */
  border-radius: 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px; /* Increased font size */
  border: 1px solid var(--color1);
}

.download-button:hover {
  background-color: var(--color3-hover);
  transform: translateY(-2px);
}

/* post-card-admin container styling */
.post-card-admin-container {
  margin-top: 10px;
  border-top: 1px solid #ccc; /* Lightened border color */
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 20px 20px; /* Rounded bottom corners */
}

/* Post title styling */
.post-title {
  font-weight: bold;
  font-size: 16px; /* Reduced font size */
  margin-bottom: 5px;
}

/* post-card-admin section styling */
.post-card-admin-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

/* post-card-admin input group */
.post-card-admin-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 1 200px; /* Flex basis for responsiveness */
}

/* Labels */
.post-card-admin-input-group label {
  font-weight: 600;
  font-size: 14px;
}

/* Inputs */
.post-card-admin-input-group input[type="text"],
.post-card-admin-input-group input[type="date"],
.post-card-admin-input-group select {
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 200px;
  font-size: 14px;
}

/* Checkboxes */
.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-label input {
  cursor: pointer;
}

/* Save button styling */
.save-button {
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  background-color: var(--color1);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
}

.save-button:hover {
  background-color: var(--color1-hover);
  transform: translateY(-2px);
}

/* Toggle button styling */
.toggle-button {
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
}

.toggle-button.enabled {
  background-color: green;
}

.toggle-button.disabled {
  background-color: red;
}

.toggle-button:hover {
  transform: translateY(-2px);
}

/* Index input group */
.index-input-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.set-index-button {
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  background-color: var(--color2);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
}

.set-index-button:hover {
  background-color: var(--color2-hover);
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 600px) {
.actions-download-container {
  flex-direction: column;
  align-items: center;
}

.download-container {
  margin-top: 10px;
}

.post-card-admin-container {
  background-color: var(--color4); /* Match main card background */
}

.post-card-admin-input-group input[type="text"],
.post-card-admin-input-group input[type="date"],
.post-card-admin-input-group select {
  max-width: 100%;
}
}
.tos-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.tos-popup-content {
  background-color: var(--color4);
  border: 2px solid var(--color1);
  color: var(--color1);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.tos-popup-content h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Existing .popup-buttons container */
.popup-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

/* New styles for the popup buttons */
.popup-button {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: var(--color1);
  color: #fff;
  border: 1px solid var(--color1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 14px;
}

.popup-button:hover {
  background-color: var(--color1-hover);
  transform: translateY(-2px);
}