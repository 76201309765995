/* src/components/PanelFull.css */

.panel-full {
  width: 300px;
  height: 360px;
  background-color: var(--color3);
  border: 2px solid var(--color1);
  border-radius: 10px;
  position: relative;
  cursor: pointer; /* Indicate that the panel is clickable */
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
  opacity: 0.8;
}

.panel-full:hover {
  transform: scale(1.03);
  opacity: 1;
}

.panel-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.panel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay for darkening effect on hover */
.panel-full::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0); /* Start transparent */
  transition: background 0.3s ease;
}

.panel-full:hover::after {
  background: rgba(0, 0, 0, 0.3); /* Dark overlay on hover */
}

/* Title and description container with transparent background */
.panel-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: var(--color5);
  opacity: 0.8;
  text-align: center;
  color: var(--color4); /* Use color variable for text */
}

.panel-title {
  font-size: 1.5em;
  margin: 0;
  text-decoration: underline;
  color: var(--color1);
}

.panel-description {
  font-size: 1em;
  margin: 5px 0 0 0;
}

.admin-actions {
  position: absolute;
  top: -350px;
  left: 10px;
  z-index: 10; /* Ensure admin buttons are above the panel background */
  display: flex;
  gap: 5px;
}

.admin-actions button {
  padding: 6px 10px;
  background-color: var(--color1);
  color: var(--color3); /* Use color variable for button text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.admin-actions button:hover {
  opacity: 0.8;
}
