/* src/components/ErrorMessage.css */
.error-message-container {
    border: 1px solid #f44336;
    background-color: #ffebee;
    color: #b71c1c;
    padding: 20px;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 600px;
  }
  
  .error-message-container h2 {
    margin-top: 0;
  }
  
  .error-message-container .actions {
    margin-top: 15px;
  }
  
  .error-message-container .actions button {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .error-message-container .actions button:hover {
    background-color: #1976D2;
  }
  
  .error-message-container .actions p {
    margin-top: 10px;
  }
  