/* global.css */
html, body {
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Source Sans Pro', sans-serif;
  }
  