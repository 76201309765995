/* src/components/Loader.css */

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    border: 8px solid var(--color4);
    border-top: 8px solid var(--color1);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  