.panels-grid {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to next line */
    justify-content: center; /* Center-align the panels */
    gap: 15px; /* Space between panels */
  }
  
  .inspiration-panel {
    position: relative; /* Enable absolute positioning inside */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px; /* Restrict max height */
    border: 2px solid var(--color1);
    border-radius: 10px; /* Optional */
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Optional */
    background-color: #fff; /* Optional */
  }
  
  .inspiration-image {
    max-height: 500px; /* Restrict image height */
    width: auto; /* Adjust width to maintain aspect ratio */
    object-fit: contain; /* Ensure the entire image fits */
    display: block;
  }
  
  .like-buttons {
    position: absolute; /* Position absolutely inside the panel */
    bottom: 10px; /* Adjust as needed */
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap onto multiple lines if needed */
    justify-content: center;
    margin: 0;
  }
  .like-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 5px; /* Add spacing around each button container */
  }
  
  .like-button-container button {
    display: flex;
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping */
    margin: 0;
    padding: 5px 10px;
    background-color: var(--color3);
    color: var(--color5);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid var(--color1);
    transition: background-color 0.3s ease;
  }
  
  .like-buttons button:hover {
    opacity: 1;
  }
  
  .admin-actions {
    position: absolute; /* Position absolutely inside the panel */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    display: flex;
    gap: 10px;
  }
  .emote-animation {
    position: absolute;
    top: -20px; /* Adjust to position the emote over the button */
    left: 50%;
    transform: translateX(-50%) scale(0);
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    animation: stamp-animation 0.5s forwards;
    pointer-events: none;
  }
  .emote-inline {
    width: 20px; /* Adjust size as needed */
    height: 20px;
    margin-right: 5px; /* Space between emote and text */
  }
  @keyframes stamp-animation {
    0% {
      transform: translateX(-50%) scale(0);
      opacity: 1;
    }
    50% {
      transform: translateX(-50%) scale(1.5);
    }
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 0;
    }
    
}