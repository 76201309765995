/* src/components/Navbar.css */

@import '../styles/variables.css';

.navbar {
  background-color: var(--color4);
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.navbar-logo img {
  display: flex;
  height: 40px;
  content : var(--logo-image); /* Use the logo-image variable */
}

.navbar-center {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
}

.navbar-center .nav-link {
  margin: 0;
}

.navbar-account {
  margin-right: 30px; /* Ensure there's no negative pull */
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-link {
  color: var(--color5);
  text-decoration: none;
  font-weight: 600;
  position: relative;
}

.nav-link.active {
  color: var(--color2);
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--color2);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-link:hover {
  color: var(--color2);
}
/* src/components/Navbar.css */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* src/components/Navbar.css */
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding-right: 2px;
}

.theme-icon {
  width: 35px;
  height: 35px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.theme-icon.spin {
  animation: spin 0.5s linear;
}

.theme-icon.fade {
  animation: fade 0.5s ease;
}

.theme-toggle:hover {
  transform: scale(1.2);
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-center {
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
  }

  .navbar-right {
    width: 100%;
    justify-content: flex-start;
  }

  .navbar-center,
  .navbar-right {
    gap: 10px;
  }

  .nav-link::after {
    bottom: -3px;
  }
}
