/* src/pages/Infos.css */
@import '../styles/variables.css';

.infos-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  min-height: 100vh;
}

.info-panel {
  width: 80%;
  max-width: 1000px;
  background-color: var(--color4transparent);
  border: 1px solid var(--color1);
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.info-panel:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: var(--color5);
  font-size: 1.2em;
}

.arrow {
  transition: transform 0.3s ease;
  font-size: 1.5em;
  color: var(--color5);
}

.arrow.open {
  transform: rotate(90deg);
  color: var(--color2);
}

.panel-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--color5);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  /* background-color: var(--color4); */
  color: var(--color5);
  font-size: 1em;
  line-height: 1.5;
  animation: expand 0.4s ease forwards;
}

@keyframes expand {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
  }
}

/* Button styling */
.button-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .info-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: var(--color3);
    color: var(--color5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .info-button:hover {
    background-color: var(--color4);
    transform: scale(1.05);
  }
  
  .info-button img {
    width: 30px;
    height: 30px;
    filter: grayscale(20%);
  }
