/* src/pages/Admin.css */

@import '../styles/variables.css'; /* Ensure this path is correct */

/* Container Styles */
.admin-panel {
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: 100vh;
}

/* Navbar Adjustments */
.navbar {
  margin-bottom: 20px;
}

/* User Management Panel */
.user-management-panel {
  max-height: 400px;
  overflow-y: auto;
  border: 2px solid var(--color4);
  background-color: var(--color4transparent);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--color4);
  border-radius: 5px;
  font-size: 1em;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--color4);
  border-radius: 5px;
  background-color: var(--color1transparent);
}

.user-item span {
  color: var(--color5);
}

.user-item button {
  padding: 8px 16px;
  background-color: var(--color1);
  color: var(--color3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.user-item button:hover {
  background-color: var(--color1dark);
}

/* Edit User Panel */
.edit-user-panel {
  max-height: 600px;
  overflow-y: auto;
  border: 2px solid var(--color4);
  background-color: var(--color4transparent);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-user-panel h2 {
  color: var(--color1);
  margin-bottom: 20px;
}

.form-group {
  color: var(--color5);
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  color: var(--color5);
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color4);
  border-radius: 5px;
  font-size: 1em;
}

.form-group input[readonly],
.form-group input[disabled] {
  background-color: #f0f0f0;
  color: var(--color5);
}

.unlocked-content, .add-unlocked-content, .pledge-history {
  margin-top: 20px;
}

.unlocked-content h3, .add-unlocked-content h3, .pledge-history h3 {
  color: var(--color1);
  margin-bottom: 10px;
}

.unlocked-content-list, .available-posts-list, .pledge-history-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid var(--color4);
  border-radius: 5px;
  padding: 10px;
  background-color: var(--color1transparent);
  margin-bottom: 10px;
}

.unlocked-content-item, .available-post-item, .ko-fi-email-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.unlocked-content-item span, .available-post-item span, .ko-fi-email-item span {
  color: var(--color5);
}

.unlocked-content-item button, .available-post-item button, .ko-fi-email-item button {
  padding: 5px 10px;
  background-color: var(--color2);
  color: var(--color3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s ease;
}

.unlocked-content-item button:hover, .available-post-item button:hover, .ko-fi-email-item button:hover {
  background-color: var(--color2dark);
}

/* Pledge History Table */
.pledge-history-table {
  width: 100%;
  border-collapse: collapse;
}

.pledge-history-table th,
.pledge-history-table td {
  border: 1px solid var(--color4);
  padding: 8px;
  text-align: left;
  color: var(--color5);
}

.pledge-history-table th {
  background-color: var(--color1);
  color: var(--color3);
}

.pledge-history-table tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Ko-fi Emails */
.ko-fi-email-list {
  list-style-type: none;
  padding: 0;
}

.ko-fi-email-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.ko-fi-email-item span {
  color: var(--color5);
}

.ko-fi-email-item button {
  padding: 5px 10px;
  background-color: var(--color2);
  color: var(--color3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s ease;
}

.ko-fi-email-item button:hover {
  background-color: var(--color2dark);
}

.form-group input[type="email"] {
  margin-top: 10px;
}
.form-group input[type="text"] {
  color: var(--color3);
}

.form-group button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--color1);
  color: var(--color3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.form-group button:hover {
  background-color: var(--color1dark);
}

/* Action Buttons */
.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.save-button {
  background-color: var(--color1);
  color: var(--color3);
}

.save-button:hover {
  background-color: var(--color1dark);
}

.cancel-button {
  background-color: var(--color5);
  color: var(--color3);
}

.cancel-button:hover {
  background-color: var(--color5dark);
}

/* Error Message Styling */
.error-message {
  color: var(--color5);
  background-color: rgba(255, 0, 0, 0.1);
  padding: 10px;
  border: 1px solid var(--color5);
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Loader Styling */
.loader {
  text-align: center;
  color: var(--color5);
  font-size: 1.2em;
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-management-panel, .edit-user-panel {
    padding: 15px;
  }

  .user-item, .unlocked-content-item, .available-post-item, .ko-fi-email-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-item button, .unlocked-content-item button, .available-post-item button, .ko-fi-email-item button {
    margin-top: 5px;
  }

  .buttons {
    flex-direction: column;
    gap: 5px;
  }

  .save-button, .cancel-button {
    width: 100%;
  }
}
