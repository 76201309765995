/* src/components/FilterPanel/FilterPanel.css */

.filter-panel {
    position: fixed;
    top: 80px;
    left: 0;
    background-color: var(--color4transparent);
    opacity: 0.5;
    border-radius: 0 20px 20px 0;
    padding: 10px;
    transition: all 0.5s ease-in-out;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--color1);
  }
  .filter-panel:hover {
    opacity: 1.0;
    background-color: var(--color4);
  }
  .filter-panel.closed {
    max-width: 40px; /* Adjust as needed */
  }
  .filter-panel.open {
    max-width: 100%;
  }
  .toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    height: 40px; /* Match height of filter buttons */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .toggle-icon {
    width: 20px;
    height: 20px;
  }
  .filter-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }
  .filter-button {
    padding: 5px;
    border-radius: 10px;
    background-color: var(--color4);
    color: var(--color5);
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    min-width: 60px;
    justify-content: center;
    height: 40px;
  }
  .filter-button.active {
    background-color: var(--color4);
    color: var(--color5);
    opacity: 1;
  }
  .filter-button.inactive {
    background-color: var(--color5);
    color: var(--color4);;
    opacity: 0.6;
  }
  .filter-button:hover {
    opacity: 0.9;
  }
  .filter-button img {
    width: 20px;
    height: 20px;
  }
  .patreon-icon {
    width: 20px;
    height: 20px;
    filter: grayscale(20%);
  }
  .posts-per-line {
    color: var(--color5);
    display: flex;
    flex-direction: column;
    gap: 0px;
    font-size: small;
    margin-left: 10px; /* Adjust spacing */
  }
  .posts-per-line label {
    margin-bottom: 0px;
  }
  .posts-per-line select {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: var(--color3);
    color: var(--color5);
    border: none;
    cursor: pointer;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .filter-panel {
      width: 200px;
    }
    .filter-panel.closed {
      width: 40px;
      height: 20px;
    }
    .toggle-icon {
      width: 18px;
      height: 18px;
    }
    .filter-button {
      padding: 8px;
      font-size: 14px;
    }
    .posts-per-line select {
      max-width: 150px;
    }
  }
  