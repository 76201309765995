/* src/styles/Home.css */

.home-page-container {
  padding-top: var(--navbar-height);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
}

.panel-full {
  transition: width 0.3s ease, height 0.3s ease;
}
.admin-toggle-container{
  padding-top: var(--navbar-height);
}
