.inspiration-vote-container {
    top: 80px;
    text-align: center; /* Center-aligns all text within the container */
    margin: 20px; /* Adds some spacing around the container */
    margin-top: 80px;
  }
  
  .inspiration-vote-container h1 {
    font-size: 2.5rem; /* Adjust font size for the title */
    color: var(--color1); /* Use a primary color from your variables */
    margin-bottom: 20px; /* Add spacing below the title */
  }
  
  .inspiration-vote-container h1 button {
    font-size: 1rem; /* Smaller font for the edit button */
    margin-left: 10px; /* Add space between the title and button */
    background-color: var(--color2); /* Secondary color for the button */
    color: var(--color3); /* Text color */
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .inspiration-vote-container h1 button:hover {
    background-color: var(--color4); /* Slightly darker shade on hover */
  }
  
  .title-editor {
    display: flex;
    justify-content: center; /* Center the title editor horizontally */
    align-items: center; /* Vertically align items in the title editor */
    gap: 10px; /* Space between input and buttons */
    margin-bottom: 20px; /* Add spacing below the editor */
  }
  
  .title-editor input {
    font-size: 1.2rem;
    padding: 5px;
    width: 60%; /* Make the input take up a significant portion of the width */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .title-editor button {
    font-size: 1rem;
    background-color: var(--color2);
    color: var(--color3);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .title-editor button:hover {
    background-color: var(--color4);
  }
/* Voting Info Panel */
.voting-info-panel {
  background-color: var(--color4);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  color: var(--color5);
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--color5);
}

.voting-info-panel .voting-title {
  font-size: 2.5rem;
  margin: 0;
  color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.voting-info-panel .voting-title button {
  font-size: 1rem;
  margin-left: 10px;
  background-color: var(--color2);
  color: var(--color3);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.voting-info-panel .voting-title button:hover {
  background-color: var(--color4transparent);
}

.voting-info-panel .title-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.voting-info-panel .title-editor input {
  font-size: 1.2rem;
  padding: 5px;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.voting-info-panel .title-editor button {
  font-size: 1rem;
  background-color: var(--color2);
  color: var(--color3);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.voting-info-panel .title-editor button:hover {
  background-color: var(--color4transparent);
}

.voting-info-panel .voting-status {
  font-size: 1.5rem;
  margin-top: 15px;
  color: var(--color5);
}

.voting-info-panel .voting-timer {
  max-width: 300px;
  background-color: var(--color1);
  color: var(--color3);
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: bold;
  margin: 10px auto; /* Center the timer horizontally */
  text-align: center; /* Ensure text inside is centered */
}
.voting-info-panel .voting-timer p {
  margin: 0;
}

/* Adjusted existing styles */
.inspiration-vote-container {
  top: 80px;
  text-align: center;
  margin: 20px;
  margin-top: 80px;
}

.admin-toggle-container {
  margin: 20px;
  text-align: right;
}

.admin-toggle-container button {
  background-color: var(--color2);
  color: var(--color3);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.admin-toggle-container button:hover {
  background-color: var(--color4transparent);
}

  