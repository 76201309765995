/* src/styles/variables.css */

:root {
    --logo-image: url('../assets/logo.png');
    --background-image: url('../assets/library_background.png');
    --color1: #B89685;
    --color2: #DB753B;
    --color3: #FFF5EA;
    --color4: #dfe0df;
    --color4transparent: #dfe0df8a;
    --color4verytransparent: #dfe0df21;
    --color5: #232020;
    --navbar-height: 80px;
    
  }
/* Dark Theme Variables */
:root.dark-theme {
  --logo-image: url('../assets/logo_dark.png');
  --background-image: url('../assets/library_background_dark.png');
  --color1: #8B6F61;
  --color2: #A85A2B;
  --color3: #2D2D2D;
  --color4: #1E1E1E;
  --color4transparent: rgba(30, 30, 30, 0.54);
  --color4verytransparent: rgba(30, 30, 30, 0.13);
  --color5: #FFF5EA;
  --navbar-height: 80px;
}

  