/* src/components/InstructionsModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears above other elements */
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content ol {
    padding-left: 20px;
  }
  
  .modal-content ul {
    padding-left: 20px;
  }
  
  .modal-content button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #1976D2;
  }
  