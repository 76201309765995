/* Container for the library page */
.library-container {
  min-height: 100vh;
  padding-top: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: filter 0.3s ease;
}

/* Search container styling */
.search-container {
  margin-top: calc(var(--navbar-height) + 20px);
  display: flex;
  justify-content: center;
}

.search-container input {
  padding: 10px;
  border: 2px solid var(--color4);
  border-radius: 5px;
  width: 80%;
  max-width: 400px;
  outline: none;
  background-color: var(--color4transparent);
  color: var(--color5);
  font-size: 1em;
}

.search-container input::placeholder {
  color: var(--color5);
  opacity: 0.7;
}
