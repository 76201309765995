/* src/components/NsfwWarning.css */

.nsfw-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .nsfw-warning-modal {
    background-color: var(--color4);
    color: var(--color5);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border: 3px solid var(--color1);
    border-radius: 10px;
  }
  
  .nsfw-warning-modal h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .nsfw-warning-modal p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .nsfw-warning-modal button {
    padding: 10px 20px;
    background-color: var(--color1);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .nsfw-warning-modal button:hover {
    background-color: var(--color1);
  }
  